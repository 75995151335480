import "./kyc-check.styles.scss";

import { RouterLinks } from "@fe/app/constants";
import { t } from "i18next";
import { init } from "onfido-sdk-ui";
import React, { useEffect, useState } from "react";
import { VitreusLoader } from "vit-ui-kit";

import checkPassed from "../../../../../assets/images/check.svg";
import crossIcon from "../../../../../assets/images/small_cross.svg";
import kycUserCheck from "../../../../../assets/images/user_check.svg";
import { config } from "../../../../config/environment.config";
import { ApplicantType, KycCheckProps, SDKType } from "./kyc-check.model";

export const KycCheck: React.FC<KycCheckProps> = ({ address, setIsVerified }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompeted, setIsCompleted] = useState(false);
  const [isCheckPassed, setCheckPassed] = useState(false);
  const [isCheckNotFound, setCheckNotFound] = useState(true);
  const [isMountingCheckPassed, setMountingCheckPassed] = useState(false);

  useEffect(() => {
    setIsVerified(isCheckPassed);
  }, [isCheckPassed]);

  useEffect(() => {
    setCheckPassed(false);
    if (address) {
      setIsLoading(true);
      fetch(`${config.ONFIDO}get-check/${address}`)
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            setIsCompleted(true);
            setCheckNotFound(false);
            if (result["status"] === "approved") {
              setCheckPassed(true);
            } else {
              setCheckPassed(false);
            }
          }
          setIsLoading(false);
          setMountingCheckPassed(true);
        })
        .catch((error) => {
          setMountingCheckPassed(true);
          setCheckNotFound(true);
          setIsLoading(false);
        });
    }
  }, [address]);

  useEffect(() => {
    if (address && !isCheckPassed && isMountingCheckPassed && (!isCheckNotFound || isCompeted)) {
      const interval = setInterval(async () => {
        setIsLoading(true);
        const check = await fetch(`${config.ONFIDO}get-check/${address}`)
          .then((response) => response.json())
          .catch((error) => {
            setIsLoading(false);
          });
        setIsLoading(false);

        if (!check) {
          return () => clearInterval(interval);
        } else {
          if (check["status"] === "approved") {
            setCheckPassed(true);

            clearInterval(interval);
            return;
          }
        }
        return;
      }, 60000);

      return () => clearInterval(interval);
    }
    return;
  }, [isCompeted, isMountingCheckPassed, address]);

  const handleVerify = async () => {
    let workflowRunId = undefined;
    if (address) {
      setIsLoading(true);
      const applicantId = await fetch(`${config.ONFIDO}create-applicant`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: address,
          lastName: address,
        }),
      })
        .then((response) => response.json())
        .then((result: ApplicantType) => {
          workflowRunId = result.workflowRunId || undefined;
          return result.applicantId;
        })
        .catch((error) => {
          setIsLoading(false);
        });

      const tokenSDK =
        applicantId &&
        (await fetch(`${config.ONFIDO}generate-sdk-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            applicantId: applicantId,
            referrer: `${window.location.href.split(RouterLinks.Statistics)[0]}/*`,
          }),
        })
          .then((response) => response.json())
          .then((result: SDKType) => result.sdkToken)
          .catch((error) => {
            setIsLoading(false);
          }));

      setIsLoading(false);

      const onfidoUI =
        tokenSDK &&
        (await init({
          token: tokenSDK,
          workflowRunId: workflowRunId,
          useModal: true,
          isModalOpen: true,
          customUI: {
            colorBorderSurfaceModal: "#6DF1B2",
            colorBackgroundSurfaceModal: "#0e1818",
            colorBackgroundButtonPrimary: "#6DF1B2",
            colorContentButtonPrimaryText: "#0E1818",
            colorContentBody: "#EEF2F2",
            colorContentTitle: "#EEF2F2",
            colorContentSubtitle: "#EEF2F2",
            colorBackgroundSelector: "#0e1818",
            colorBackgroundDocTypeButton: "#0e1818",
            colorBorderButtonPrimary: "transparent",
            colorBorderButtonPrimaryHover: "transparent",
            colorBorderButtonPrimaryActive: "#4CA97D",
            colorBackgroundButtonPrimaryHover: "#4CA97D",
            colorBackgroundButtonPrimaryActive: "#4CA97D",
            colorBorderDocTypeButtonHover: "#6DF1B2",
            colorBackgroundLinkHover: "#4CA97D",
            colorBackgroundLinkActive: "4CA97D",
            colorBorderLinkUnderline: "#4CA97D",
            colorBackgroundInfoPill: "#6DF1B2",
            colorBackgroundQRCode: "white",
            colorContentButtonSecondaryText: "#6DF1B2",
            colorBorderButtonSecondary: "#6DF1B2",
            colorBorderButtonSecondaryHover: "#6DF1B2",
            colorBackgroundButtonSecondary: "transparent",
          },
          onModalRequestClose: function () {
            onfidoUI && onfidoUI.setOptions({ isModalOpen: false });
          },
          shouldCloseOnOverlayClick: true,
          onComplete: async () => {
            setIsLoading(true);
            await fetch(`${config.ONFIDO}create-check`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                applicantId: applicantId,
                reportNames: ["document", "facial_similarity_photo"],
                address: address,
              }),
            })
              .then((response) => response.json())
              .then((result: SDKType) => result.sdkToken)
              .then(() => {
                setIsLoading(false);
                setIsCompleted(true);
              })
              .catch((error) => {
                setIsLoading(false);
              });
          },

          onError(error) {
            console.log("INIT ERROR", error);
          },
          steps: ["welcome", "document", "face", "complete"],
        }));
    } else {
      alert("Set FirstName and LastName");
    }
  };

  return (
    <>
      <div className="flex gap-[20rem] items-center sm:gap-4">
        {!isCheckPassed ? (
          <>
            <div className="bg-[#2F1F20] h-fit flex gap-[6rem] items-center px-12 py-4 rounded-full">
              <img src={crossIcon} alt="cross" />
              <span className="text-red text-[14rem] leading-[18rem]">{t("kyc.not_verified")}</span>
            </div>
            <button
              className="cursor-pointer hover:bg-[#0e3933] p-12 rounded-small"
              disabled={!address || isLoading}
              onClick={handleVerify}
            >
              {isLoading ? (
                <div className="w-full h-[50rem] flex justify-center items-center">
                  {VitreusLoader}
                </div>
              ) : (
                <div className="flex gap-[10rem] items-center ">
                  <img src={kycUserCheck} alt="check" />
                  <span className="text-green text-[12rem] leading-[16rem] uppercase">
                    {t("kyc.pass_procedure")}
                  </span>
                </div>
              )}
            </button>
          </>
        ) : (
          <div className="bg-vitreus-green-800 h-fit flex gap-[6rem] items-center px-12 py-4 rounded-full">
            <img src={checkPassed} alt="passed" />
            <span className="text-green text-[14rem] leading-[18rem] capitalize">
              {t("kyc.verified")}
            </span>
          </div>
        )}
      </div>
      <div id="onfido-mount"></div>
    </>
  );
};
