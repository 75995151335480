import { useMutation } from "@tanstack/react-query";

import { Api } from "../helpers/Api";

export const useExportCsv = () =>
  useMutation(async () => {
    const csv = await Api.get<Blob>("/affiliate/csv", "csv");

    const blob = new Blob([csv]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `affiliate-data.csv`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  });
