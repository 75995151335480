import "react-tooltip/dist/react-tooltip.css";

import React, { FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

// TODO: make it work!!!

export const Tooltip: FC<{ name: string; tooltip: string }> = ({ name, tooltip }) => {
  return (
    <ReactTooltip
      anchorSelect={`.${name}`}
      id={name}
      content={tooltip}
      place="top"
      opacity={1}
      z-index={9999}
      style={{
        backgroundColor: "#182a29",
        maxWidth: "400px",
        textAlign: "center",
        borderRadius: "15px",
      }}
    />
  );
};
