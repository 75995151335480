import "./table.styles.scss";

import { useBlockAddress } from "@fe/app/actions/useBlockAddress";
import { InvitedAffiliate, useGetInvitedAffiliates } from "@fe/app/actions/useGetInvitedAffiliates";
import { Roles } from "@fe/app/constants";
import { Address } from "@fe/app/ui-kit/address";
import { Loader } from "@fe/app/ui-kit/loader";
import { ModalWindow } from "@fe/app/ui-kit/modal-window";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Table, TableDataType } from "vit-ui-kit";

import { ReactComponent as Delete } from "../../../assets/images/delete.svg";
import { DeleteAddress } from "./components/delete-modal/delete-modal";

export const AdminTable: React.FC<{ role: Roles }> = ({ role }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState("");

  const { data, isLoading, refetch } = useGetInvitedAffiliates({ limit: 0, offset: 0 });
  const { mutate } = useBlockAddress();

  const handleOpen = (address: string) => {
    !isOpen && setIsOpen(true);
    setAddress(address);
  };

  const handleClose = () => {
    isOpen && setIsOpen(false);
    setAddress("");
    setTimeout(refetch, 1000);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(timer);
  });

  const handleUnblock = (address: string) => {
    mutate(address);
    setTimeout(refetch, 1000);
  };

  const handleBlockUnblock = (address: string, isBlocked: boolean) => {
    if (!isBlocked) {
      handleOpen(address);
    } else {
      handleUnblock(address);
    }
  };

  const adminAndManagerColumns = [
    {
      header: t("name"),
      cell: (info: CellContext<InvitedAffiliate, string>) => {
        if (info.getValue().slice(0, 2) === "0x") {
          return <Address className="text-white" address={info.getValue()} />;
        }
        return info.getValue();
      },
      accessorKey: "name",
    },
    {
      header: t("address"),
      cell: (info: CellContext<InvitedAffiliate, string>) => <Address address={info.getValue()} />,
      accessorKey: "address",
    },
  ];

  const adminColumns = [
    {
      header: t("block/unblock"),
      cell: (info: CellContext<InvitedAffiliate, string>) => {
        const { isBlocked } = info.row.original;

        return (
          <div
            className="flex gap-8 items-center cursor-pointer hover:font-bold"
            onClick={() => handleBlockUnblock(info.getValue(), isBlocked)}
          >
            {!isBlocked ? (
              <>
                <Delete />
                <p className="m-0 p-0 text-red uppercase font-medium">Block address</p>
              </>
            ) : (
              <p className="m-0 p-0 text-green uppercase font-medium">Unblock address</p>
            )}
          </div>
        );
      },
      accessorKey: "address",
    },
  ];

  const columnNames: ColumnDef<InvitedAffiliate, string>[] =
    role === Roles.Admin
      ? [...adminAndManagerColumns, ...adminColumns]
      : [...adminAndManagerColumns];

  return (
    <div className="overflow-hidden rounded-large py-12 border-vitreus-green-800 border pl-20">
      {isLoading ? (
        <Loader />
      ) : data && data?.users?.length ? (
        <Table
          data={data.users as unknown as TableDataType[]}
          className="Table center"
          columns={columnNames as unknown as ColumnDef<TableDataType, string>[]}
        />
      ) : (
        <span>No one is invited</span>
      )}
      {isOpen && (
        <ModalWindow closeModal={handleClose}>
          <DeleteAddress handleClose={handleClose} address={address} />
        </ModalWindow>
      )}
    </div>
  );
};
