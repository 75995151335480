import "./styles.scss";

import { SideBarLinkProps } from "@fe/app/constants/sidebar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export interface NavLinkProps {
  sidebarOption: SideBarLinkProps;
}

export const NavLink: React.FC<NavLinkProps> = ({ sidebarOption }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { link, title, image } = sidebarOption;

  const isActive = pathname === link;

  const handleClick = () => {
    navigate(`..${link}`);
  };

  return (
    <button
      onClick={handleClick}
      className="flex flex-col wrapper gap-8 items-center cursor-pointer rounded-[12rem] "
    >
      <div
        className={`icon-wrapper ${isActive ? "active" : ""} ${
          isActive ? "bg-gradient-to-b  from-[#6DF1B2] to-[#1CC5F9]" : ""
        } p-12 rounded-[12rem]`}
      >
        {image}
      </div>
      <span
        className={`capitalize bg-clip-text  text-[12rem] leaning-[16rem] whitespace-nowrap ${
          isActive
            ? "text-transparent bg-gradient-to-b  from-[#6DF1B2] to-[#1CC5F9]"
            : "text-[#597F7D]"
        }`}
      >
        {t(`${title}`)}
      </span>
    </button>
  );
};
