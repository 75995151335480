import { AddAddressButton } from "@fe/app/components/add-address/add-address-button";
import { AdminTable } from "@fe/app/components/admin-table/admin-table";
import { DepositBLock } from "@fe/app/components/deposit/deposit";
import { ExportButtonButton } from "@fe/app/components/export-button/export-button";
import { RewardsTable } from "@fe/app/components/rewards-table/rewards-table";
import { Roles } from "@fe/app/constants";
import { useUser } from "@fe/app/hooks";
import { useClaimContract } from "@fe/app/hooks/useContract";

export const AdminPage = () => {
  const { user } = useUser();
  const { claimData, refetch } = useClaimContract();

  return (
    <div className="flex flex-col gap-20 justify-center mt-40 mx-20">
      <div className="flex gap-20 justify-end">
        {user?.role === Roles.Admin && (
          <>
            {" "}
            <AddAddressButton />
            <ExportButtonButton />
          </>
        )}

        {user?.role === Roles.Manager && claimData?.needToDeposit && (
          <DepositBLock
            pendingRewards={claimData?.needToDeposit}
            deposit={claimData?.handleDeposit}
            refetch={refetch}
          />
        )}
      </div>
      <RewardsTable />

      {user && <AdminTable role={user.role} />}
    </div>
  );
};
