import { ChartPointDto } from "../pages/statistics/requests";

export enum Period {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}

export interface TxsGraphPointDto {
  date: string;
  value: number;
}

export interface ChartValue {
  mainValue: number;
  label: string;
}

interface FormatChartData {
  data: ChartPointDto[];
  period: Period;
}

export const formatChartData = ({ data, period }: FormatChartData) => {
  const formattedData: ChartValue[] = [];

  data?.forEach((el) => {
    formattedData.unshift({
      mainValue: el.revenue || 0,
      label: el.timestamp,
    });
  });

  return formattedData;
};
