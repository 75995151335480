import { Period } from "@fe/app/helpers";
import { ChartWrapper } from "@fe/app/ui-kit";
import { VitreusLoader } from "vit-ui-kit";

import { useGetChartData } from "../../requests";

export const StatisticChartBlock: React.FC = () => {
  const { data, isLoading } = useGetChartData();

  if (isLoading) {
    return (
      <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
    );
  }
  const isSomeDataValue = data?.length && data?.reduce((acc, curr) => acc + curr.revenue, 0);

  return data && isSomeDataValue ? (
    <ChartWrapper period={Period.month} data={data} title="Earnings" subtitle="USD" />
  ) : null;
};
