/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from "react";

interface DisclaimerModalProps {
  handleClose: () => void;
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ handleClose }) => {
  const popupScrollable = useRef(null);
  const [disabled, setDisabled] = useState(true);

  const handleScroll = (e: React.UIEvent<HTMLParagraphElement>) => {
    const target = e.target as HTMLElement;
    if (target.scrollTop > 0.9 * (target.scrollHeight - target.offsetHeight)) {
      setDisabled(false);
    }
  };

  return (
    <div className="relative flex flex-col w-[470rem] h-fit bg-[#182A29] justify-center rounded-[20rem] sm:w-full sm:m-auto ">
      <div className="w-full flex flex-col justify-between bg-vitreus-green-800 rounded-large animate-fade-in p-24 text-white ml-8">
        <div className="text-p3 pb-20 uppercase border-b border-b-vitreus-green-600 text-center">
          Affiliate Marketing Agreement
        </div>
        <p
          ref={popupScrollable}
          className="text-p2 my-20 h-[350rem] overflow-y-scroll pr-[6rem]"
          onScroll={handleScroll}
        >
          <b>DISCLAIMER: PLEASE READ CAREFULLY BEFORE PROCEEDING</b>
          <br />
          <br />
          By joining the Affiliate Program, or clicking on “I agree” (or a similar box or button)
          when you sign up for an Affiliate Account, you agree to be bound by the applicable
          sections of the following Affiliate Marketing Agreement{" ( the "}
          <b>“Agreement”</b> {" ). "} The Agreement is between you, as the Affiliate, and
          Collaborative Digital Innovations LLC{" ( the "}
          <b>“Company”</b> {" ). "}
          You can review the current version of the Agreement at any time at{" "}
          <a
            className="px-[0] py-0 lowercase text-white underline text-p2"
            href="https://vitreus.io/privacy-statement/?cmplz_region_redirect=true&cmplz-region=us"
            target="_blank"
            rel="noreferrer"
          >
            https://vitreus.io/privacy-statement
          </a>{" "}
          . The Company reserves the right to update and change the Agreement by posting updates and
          changes here:{" "}
          <a
            className="px-[0] py-0 lowercase text-white underline text-p2"
            href="https://vitreus.io/privacy-statement/?cmplz_region_redirect=true&cmplz-region=us"
            target="_blank"
            rel="noreferrer"
          >
            https://vitreus.io/privacy-statement
          </a>{" "}
          . If a significant change is made, we will provide reasonable notice by email, posting a
          notice on the Affiliate Blog, or in the Affiliate Dashboard. YOu are advised to check the
          Agreement from time to time for any updates or changes that may impact you. Any reference
          to the Agreement includes any and all terms and documents incorporated by reference.
          <br />
          <br />
          You must read, agree with and accept all of the terms and conditions contained in this
          Agreement, including our{" "}
          <a
            className="px-[0] py-0 text-white underline text-p2 capitalize"
            href="https://vitreus.io/privacy-statement/?cmplz_region_redirect=true&cmplz-region=us"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and our Terms of Use Policy before you may become an Affiliate. For the avoidance of
          doubt, our{" "}
          <a
            className="px-[0] py-0 capitalize text-white underline text-p2"
            href="https://vitreus.io/privacy-statement/?cmplz_region_redirect=true&cmplz-region=us"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and Terms of Use Policy form part of this Agreement and are incorporated by reference. For
          the purposes of the Affiliate Program and this Agreement, all references to “Account” and
          “Services” in our Terms of Use Policy will be deemed to refer to “Affiliate Account” and
          “Services in the Affiliate Program” respectively. Some types of activities in the
          Affiliate Program may require that you agree to additional terms
          {" ("}
          <b>“Additional Terms”</b> {"). "}
          Such Additional Terms are incorporated into this Agreement by reference. In the event of a
          conflict or inconsistency between this Agreement and the Additional Terms, the Additional
          Terms will govern, to the extent of such conflict or inconsistency.
          <br />
          <br />
          <b>
            Everyday language summaries are provided for convenience only and are not legally
            binding. Please read the entire Agreement for the complete picture of your legal
            requirements. This Agreement governs your activities as an Affiliate, including access
            to the Affiliate Dashboard and participates in the activities described on the Affiliate
            Program website. Be sure to occasionally check back for updates.
          </b>
        </p>
        <button
          onClick={handleClose}
          disabled={disabled}
          className="w-full text-center py-[17rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black disabled:opacity-50"
        >
          I agree
        </button>
      </div>
    </div>
  );
};

export default DisclaimerModal;
