import { useGenerateLink } from "@fe/app/actions/useGenerateLink";
import { CopyText } from "@fe/app/ui-kit/copy";
import { ReactComponent as ConnectedLink } from "@fe/assets/images/connected-link.svg";
import { ReactComponent as Close } from "@fe/assets/images/cross.svg";
import React from "react";
import { Input, VitreusLoader } from "vit-ui-kit";

export interface GenerateLinkProps {
  handleClose: () => void;
}

export const GenerateLink: React.FC<GenerateLinkProps> = ({ handleClose }) => {
  const { data, isLoading } = useGenerateLink();

  return (
    <div className="relative flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] sm:w-full sm:m-auto">
      <div className="absolute top-0 right-[-30rem] sm:right-[5rem]" onClick={handleClose}>
        <Close className="cursor-pointer" />
      </div>
      {isLoading ? (
        <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
      ) : data?.message ? (
        <div className="w-full h-[100rem] p-[30rem] flex justify-center items-center text-[20rem] leaning-[28rem] font-medium text-white text-center">
          Something went wrong! Try to login again or wait a bit
        </div>
      ) : (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="flex gap-12">
              <div>
                <ConnectedLink />
              </div>
              <div className="text-[20rem] leaning-[28rem] font-medium text-white">
                Link generated
              </div>
            </div>
            <div className="text-[14rem] leaning-[18rem] text-[#597F7D]">
              Your individual link is ready. Use it to send to your users and get your percentage
            </div>
          </div>
          <div className="bg-[#132221] flex flex-col p-24 rounded-[12px]">
            <div className="flex gap-12 w-full items-center mb-24">
              <Input value={data?.url} readOnly className="w-full" />
              <CopyText text={data?.url || ""} />
            </div>
            <div className="mb-16 mx-auto rounded-[20px] overflow-hidden">
              <img src={data?.qrCode} alt="QR Code" />
            </div>
          </div>
          {/* TODO: upgrade StrokeButton to this styles */}
          <button
            onClick={handleClose}
            className="w-full text-center py-[17rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
          >
            close
          </button>
        </div>
      )}
    </div>
  );
};
