import { Period, Roles } from "@fe/app/constants";
import { useUser } from "@fe/app/hooks";
import {
  ContentInTab,
  ContentInTabProps,
} from "@fe/app/ui-kit/statistics-top-blocks-wrapper/coontent-in-tab";
import { StatisticsTopBlockWithTabs } from "@fe/app/ui-kit/statistics-top-blocks-wrapper/statistics-top-blocks-wrapper-with-tabs";
import React from "react";
import { VitreusLoader } from "vit-ui-kit";

import { useGetNumberOfMembers } from "../../requests";

const periodFormatter: { [key in Period]: string } = {
  day: `24 hour`,
  week: `7 days`,
  month: `30 days`,
};

export const MembersBlock: React.FC = () => {
  const { data: dayData, isLoading: loadingDay } = useGetNumberOfMembers({ period: Period.Day });
  const { data: weekData, isLoading: loadingWeek } = useGetNumberOfMembers({
    period: Period.Week,
  });
  const { data: monthData, isLoading: loadingMonth } = useGetNumberOfMembers({
    period: Period.Month,
  });

  const { user } = useUser();

  if (loadingDay || loadingWeek || loadingMonth) {
    return (
      <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
    );
  }

  if (!monthData?.numberOfMembers && !weekData?.numberOfMembers && !dayData?.numberOfMembers) {
    return null;
  }

  const formattedData: ContentInTabProps[] = [dayData, weekData, monthData].reduce((acc, curr) => {
    const data: ContentInTabProps = {
      title: `NUMBER OF ${user?.role === Roles.Admin ? "AFFILIATES" : "MEMBERS"}`,
      titleAddition: "",
      subTitle: `Total members: ${curr?.totalNumberOfMembers}`,
      mainNumber: `${curr?.numberOfMembers}`,
      periodName: `New ${user?.role === Roles.Admin ? "affiliates" : "members"} in ${
        periodFormatter[curr?.period || Period.Day]
      }`,
      percentage: curr?.numberOfMembersDiff ? +(curr?.numberOfMembersDiff * 100).toFixed(2) : 0,
    };

    acc.push(data);
    return acc;
  }, [] as ContentInTabProps[]);

  return (
    <StatisticsTopBlockWithTabs tabs={["today", "this week", "this month"]}>
      {formattedData.map((content) => (
        <ContentInTab key={content?.periodName} {...content} />
      ))}
    </StatisticsTopBlockWithTabs>
  );
};
