import "./withdraw.styles.scss";

import { config } from "@fe/app/config/environment.config";
import { USDFormatter } from "@fe/app/helpers";
import { useModal } from "@fe/app/hooks";
import { useGetSignature } from "@fe/app/hooks/useGetSignature";
import { ModalWindow } from "@fe/app/ui-kit/modal-window";
import { ReactComponent as Add } from "@fe/assets/images/add.svg";
import { ReactComponent as Close } from "@fe/assets/images/cross.svg";
import { SmartContract, useAddress, Web3Button } from "@thirdweb-dev/react";
import { BaseContract } from "ethers";
import { t } from "i18next";
import React, { useState } from "react";
import { VitreusLoader } from "vit-ui-kit";

import affiliateContract from "../../../../abi/AffiliatePayoutRegistry.json";
import { KycCheck } from "../kyc-check";

export interface WithdrawBLockProps {
  pendingRewards?: string;
  claimed?: string;
  claim?: (signature: string, smartContract: SmartContract<BaseContract>) => Promise<any>;
  refetch: () => void;
}

export interface ClaimBLockProps {
  pendingRewards?: string;
  claim: (signature: string, smartContract: SmartContract<BaseContract>) => Promise<any>;
  handleClose: () => void;
  refetch: () => void;
}

const ClaimBLock: React.FC<ClaimBLockProps> = ({ pendingRewards, claim, handleClose, refetch }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [claimed] = useState(pendingRewards || "0");

  const { data, isLoading: signatureLoading } = useGetSignature();

  const handleGoClaim = async (contract: SmartContract<BaseContract>) => {
    try {
      setLoading(true);
      await claim(data?.signatureEIP712 || "", contract);
      await refetch();
      setIsSuccess(true);
    } catch (e) {
      setLoading(false);
      setError(`${e}`);
    }
    setLoading(false);
  };

  return (
    <div className="relative flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] sm:w-screen sm:m-auto">
      <div className="absolute top-0 right-[-30rem] sm:right-[7rem]" onClick={handleClose}>
        <Close className="cursor-pointer" />
      </div>
      {isLoading || signatureLoading ? (
        <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
      ) : error ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[16rem] leaning-[16rem] font-medium text-green text-center">
              {error.includes("user rejected") ? "User rejected transaction" : error}
            </div>
          </div>

          <button
            className="flex w-full items-center justify-center gap-8 w-fit text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
            onClick={handleClose}
          >
            close
          </button>
        </div>
      ) : isSuccess ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[20rem] leaning-[28rem] font-medium text-white">
              {`You successfully claimed: ${USDFormatter(claimed)}$`}
            </div>
          </div>
          <button
            className="flex w-full items-center gap-8 w-fit justify-center text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
            onClick={handleClose}
          >
            close
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="flex gap-12">
              <div>
                <Add />
              </div>
              <div className="text-[20rem] leaning-[28rem] font-medium text-white uppercase">
                WIthdraw earned
              </div>
            </div>
            <div className="text-[14rem] leaning-[18rem] text-vitreus-black-100">
              Сlick button to withdraw all your rewards
            </div>
            <div className="text-[16rem] leaning-[20rem] text-white">
              {`Rewards: ${USDFormatter(claimed || 0)}$`}
            </div>
          </div>
          <div className="flex flex-col gap-10"></div>

          <Web3Button
            className="web3Button"
            contractAbi={affiliateContract.abi}
            contractAddress={config.AFFILIATE_CONTRACT}
            action={(contract) => handleGoClaim(contract)}
            isDisabled={signatureLoading || !pendingRewards || !Number(pendingRewards)}
          >
            apply withdraw
          </Web3Button>
        </div>
      )}
    </div>
  );
};

export const WithdrawBLock: React.FC<WithdrawBLockProps> = ({
  pendingRewards,
  claimed,
  claim,
  refetch,
}) => {
  const [isOpenModal, toggleOpenModal] = useModal(false);
  const [isKycPassed, setIsPassed] = useState(false);
  const address = useAddress();

  const handleSetPassed = (value: boolean) => {
    setIsPassed(value);
  };

  return (
    <div className="w-full h-fit flex justify-end gap-24 mb-24 sm:flex-col md:gap-12 sm:items-end">
      <KycCheck setIsVerified={handleSetPassed} address={`${address}`} />
      {!!(+(pendingRewards || "0") || +(claimed || "0")) && (
        <>
          <div className="balance flex justify-between gap-16 items-center bg-vitreus-green-800 px-[24rem] rounded-small md:px-[12rem] sm:gap-4 sm:px-[8rem] sm:justify-start sm:w-fit  sm:py-[8rem]  xs:hidden ">
            <div className="flex gap-8 sm:gap-0">
              <span className="capitalize">{t("rewards")}:</span>
              <span>$ {USDFormatter(pendingRewards || 0)}</span>
            </div>
            <div className="h-full w-[1rem] bg-vitreus-green-600"></div>
            <div className="flex gap-8 sm:gap-0">
              <span className="capitalize">{t("withdraw")}:</span>
              <span>$ {USDFormatter(claimed || 0)}</span>
            </div>
          </div>
          {isKycPassed && (
            <button
              className="flex items-center gap-8 w-fit text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
              onClick={toggleOpenModal}
            >
              withdraw
            </button>
          )}
        </>
      )}

      {isOpenModal && (
        <ModalWindow closeModal={toggleOpenModal}>
          {claim ? (
            <ClaimBLock
              refetch={refetch}
              handleClose={toggleOpenModal}
              claim={claim}
              pendingRewards={pendingRewards}
            />
          ) : (
            <></>
          )}
        </ModalWindow>
      )}
    </div>
  );
};
