export const environment = {
  production: true,
  environment: `production`,
  SUPABASE_URL: `https://czonnletnhmsyxbhujyt.supabase.co`,
  SUPABASE_ANON_KEY: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9`,
  thirdwebSecretKey: `ZBwLTq8dqY1gROg0D6K7QwZjl3zeS5S_BbMf_z51iBuHyDwGPlFGqFVU5T7TgoK8_Z3QcjonvUwutC2XB_itBw`,
  affiliateApiUrl: `https://affiliate.compliq.io`,
  THIRD_WEB_CLIENT_ID: `e8ac72a9c8bf3e4bd6376d1fec2208c3`,
  ACTIVE_CHAIN: `ethereum`,
  SENTRY_DSN: `https://5abf519b80dad54d6bac46e7f033cdd2@o4505596624502784.ingest.sentry.io/4505647443214336`,
  SENTRY_AUTH_TOKEN: `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`,
  ACTIVE_CHAIN_ID: `1`,
  AFFILIATE_CONTRACT: `0x876275421e91039328Be3f8c0351B8baA3716976`,
  DEPOSIT_GAS_LIMIT: ` 50000`,
  CLAIM_GAS_LIMIT: ` 50000`,
  USDC_ADDRESS: `0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48`,
  ONFIDO: `https://affiliate.compliq.io/kyc/`
};
