import { Period } from "@fe/app/constants";
import { Api } from "@fe/app/helpers/Api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export interface GeneratedRevenueDto {
  generatedRevenue: number;
  revenueDiff: number;
  totalRevenue: number;
}

export interface GeneratedRevenueData extends GeneratedRevenueDto {
  period: Period;
}

export const useGetGeneratedRevenue = ({
  period,
}: {
  period: Period;
}): UseQueryResult<GeneratedRevenueData> =>
  useQuery([`useGetRevenue${period}`], async () => {
    const response = await Api.get<GeneratedRevenueDto>(
      `/analytics/generated-revenue?period=${period}`,
    );

    return { ...response, period: period };
  });
