import "./styles.scss";

import { Roles } from "@fe/app/constants/roles";
import { sidebarLink } from "@fe/app/constants/sidebar";
import { useUser } from "@fe/app/hooks";
import ConnectWalletBtn from "@fe/app/ui-kit/ConnectWalletBtn/ConnectWalletBtn";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Header as HeaderUI } from "vit-ui-template";

import { GenerateLinkButton } from "../../components/generate-link/generate-link-button";
import { HeaderLinks } from "./components/header-links/header-links";

export const Header: FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <HeaderUI text={t("Vitreus")} className="header h-[82px]">
      <>
        <div className="mr-16 sm:hidden"></div>
        {user && <HeaderLinks links={sidebarLink} />}
        {user?.role === Roles.User && !user.isBlocked && <GenerateLinkButton />}
        <ConnectWalletBtn />
      </>
    </HeaderUI>
  );
};
