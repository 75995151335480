import "./styles.scss";

import { config } from "@fe/app/config/environment.config";
import { USDFormatter } from "@fe/app/helpers";
import { useModal } from "@fe/app/hooks";
import { useUSDC } from "@fe/app/hooks/useUSDC";
import { ModalWindow } from "@fe/app/ui-kit/modal-window";
import { ReactComponent as Add } from "@fe/assets/images/add.svg";
import { ReactComponent as Close } from "@fe/assets/images/cross.svg";
import { SmartContract, Web3Button } from "@thirdweb-dev/react";
import { BaseContract } from "ethers";
import React, { useState } from "react";
import { GradientButton, VitreusLoader } from "vit-ui-kit";

import affiliateContract from "../../abi/AffiliatePayoutRegistry.json";

export interface DepositBLockProps {
  pendingRewards?: string;
  deposit?: (smartContract: SmartContract<BaseContract>) => Promise<any>;
  refetch: () => void;
}

export interface DepositModalBLockProps {
  pendingRewards?: string;
  deposit: (smartContract: SmartContract<BaseContract>) => Promise<any>;
  handleClose: () => void;
  refetch: () => void;
}

const DepositModalBLock: React.FC<DepositModalBLockProps> = ({
  pendingRewards,
  deposit,
  handleClose,
  refetch,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [deposited] = useState(Number(pendingRewards) || 0);
  const { allowance, approve, isLoading: approveLoading, refetchAllowance } = useUSDC();

  const handleClickCLose = async () => {
    await refetch();
    handleClose();
  };

  const handleGoDeposit = async (contract: SmartContract<BaseContract>) => {
    try {
      setLoading(true);
      const response = await deposit(contract);
      await refetch();
      setIsSuccess(true);
    } catch (e) {
      setLoading(false);
      setError(`${e}`);
    }
    setLoading(false);
  };

  const handleGoAlow = async () => {
    try {
      await refetch();
      await approve({
        value: String((Number(pendingRewards) + 0.01).toFixed(6)) || "0",
      });
      await refetchAllowance();
    } catch (e) {
      setError(`${e}`);
    }
  };

  return (
    <div className="relative flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] sm:w-screen sm:m-auto">
      <div className="absolute top-0 right-[-30rem] sm:right-[5rem]" onClick={handleClose}>
        <Close className="cursor-pointer" />
      </div>
      {isLoading || approveLoading ? (
        <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
      ) : error ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[16rem] leaning-[20rem] font-medium text-green flex justify-center text-center">
              {error.includes("rejected") || error.includes("disapproved")
                ? "User rejected transaction"
                : error}
            </div>
          </div>

          <button
            className="flex w-full items-center justify-center gap-8 w-fit text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
            onClick={handleClose}
          >
            close
          </button>
        </div>
      ) : isSuccess ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[20rem] leaning-[28rem] font-medium text-white">
              {`You successfully deposited: ${deposited}$`}
            </div>
          </div>
          <button
            className="flex w-full items-center gap-8 w-fit justify-center text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
            onClick={handleClickCLose}
          >
            close
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="flex gap-12">
              <div>
                <Add />
              </div>
              <div className="text-[20rem] leaning-[28rem] font-medium text-white uppercase">
                Deposit
              </div>
            </div>
            <div className="text-[14rem] leaning-[18rem] text-vitreus-black-100">
              Сlick button to deposit all rewards for previous rounds
            </div>
            <div className="text-[16rem] leaning-[20rem] text-white">
              {`Need to deposit: ${USDFormatter(pendingRewards || 0)}$`}
            </div>
          </div>
          <div className="flex flex-col gap-10"></div>
          {allowance < Number(pendingRewards) ? (
            <GradientButton
              disabled={!pendingRewards || !Number(pendingRewards) || approveLoading}
              className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium w-full"
              text={"approve"}
              onClick={handleGoAlow}
            />
          ) : (
            <Web3Button
              className="web3Button"
              contractAbi={affiliateContract.abi}
              contractAddress={config.AFFILIATE_CONTRACT}
              action={(contract) => handleGoDeposit(contract)}
              isDisabled={!pendingRewards || !Number(pendingRewards) || approveLoading}
            >
              apply deposit
            </Web3Button>
          )}
        </div>
      )}
    </div>
  );
};

export const DepositBLock: React.FC<DepositBLockProps> = ({ pendingRewards, deposit, refetch }) => {
  const [isOpenModal, toggleOpenModal] = useModal(false);

  const handleOpen = () => {
    if (deposit) {
      toggleOpenModal();
    }
  };

  return (
    <div className="w-full flex justify-end gap-24">
      <button
        className="flex items-center gap-8 w-fit text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
        onClick={handleOpen}
      >
        deposit
      </button>

      {isOpenModal && (
        <ModalWindow closeModal={toggleOpenModal}>
          {deposit ? (
            <DepositModalBLock
              refetch={refetch}
              handleClose={toggleOpenModal}
              deposit={deposit}
              pendingRewards={pendingRewards}
            />
          ) : (
            <></>
          )}
        </ModalWindow>
      )}
    </div>
  );
};
