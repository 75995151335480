import { Roles } from "@fe/app/constants/roles";
import { useUser } from "@fe/app/hooks/useUser";
import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";

export const RoleRoute: FC<{ children: ReactElement; roles: Roles[] }> = ({ children, roles }) => {
  const { user } = useUser();

  if (user && roles.includes(user.role)) {
    return children;
  }

  return <Navigate to={"/"} />;
};
