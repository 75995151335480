import { round } from "@fe/app/helpers";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { cellTypes, TableDataType } from "vit-ui-kit";

export const columnNames: ColumnDef<TableDataType, string>[] = [
  {
    header: "Address",
    cell: (info: CellContext<TableDataType, string>) => (
      <div>{`${info.getValue().slice(0, 4)}...${info.getValue().slice(-6)}`}</div>
    ),
    accessorKey: "address",
  },
  {
    header: "Revenue",
    cell: (info) => (
      <span className="text-white w-full flex">{round(Number(info.getValue()), 2)}</span>
    ),
    accessorKey: "revenue",
  },
];
