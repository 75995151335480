import { USDFormatter } from "@fe/app/helpers";
import { useClaimContract } from "@fe/app/hooks/useContract";
import React from "react";
import { VitreusLoader } from "vit-ui-kit";

export const RewardsTable: React.FC = () => {
  const { claimData, isClaimContractLoading } = useClaimContract();

  if (isClaimContractLoading) {
    return (
      <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
    );
  }

  const data = [
    {
      name: "Total Deposits",
      value: `${USDFormatter(claimData?.totalDeposits || 0)} $`,
    },
    {
      name: "Total Rewards",
      value: `${USDFormatter(claimData?.totalRewards || 0)} $`,
    },
    {
      name: "Total Claimed",
      value: `${USDFormatter(claimData?.totalClaimed || 0)} $`,
    },
    {
      name: "Need to deposit",
      value: `${USDFormatter(claimData?.needToDeposit || 0)} $`,
    },
  ];

  return (
    <div>
      <h5 className="text-h5 text-white mb-24 text-center">
        {`Rewards statistic`.toLocaleUpperCase()}
      </h5>
      <div className="w-full overflow-x-auto flex gap-24 justify-center flex-wrap sm:gap-8">
        {data.map(({ name, value }) => (
          <div key={name} className="bg-vitreus-green-800 p-12 rounded-small ">
            <p>{name}</p>
            <div className="flex flex-nowrap gap-4">
              Value:<p className="text-white"> {value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
