import cx from "classnames";
import { FC, ReactElement } from "react";

export const StatisticsTopBlock: FC<{
  children: string | ReactElement | ReactElement[];
  className?: string;
  key?: string;
}> = ({ children, className, key }) => {
  return (
    <div
      key={key}
      className={cx("rounded-[20px] bg-vitreus-green-800 pl-24 pt-32 pr-24 pb-32", className)}
    >
      {children}
    </div>
  );
};
