import { useBlockAddress } from "@fe/app/actions/useBlockAddress";
import { ReactComponent as Close } from "@fe/assets/images/cross.svg";
import { ReactComponent as Delete } from "@fe/assets/images/delete.svg";
import React from "react";
import { GradientButton } from "vit-ui-kit";

export interface DeleteAddressProps {
  handleClose: () => void;
  address: string;
}

export const DeleteAddress: React.FC<DeleteAddressProps> = ({ handleClose, address }) => {
  const { mutate } = useBlockAddress();

  const handleDelete = () => {
    mutate(address);
    handleClose();
  };

  return (
    <div className="relative flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] sm:w-screen sm:m-auto">
      <div className="absolute top-0 right-[-30rem] sm:right-[5rem] " onClick={handleClose}>
        <Close className="cursor-pointer" />
      </div>

      <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
        <div className="flex flex-col gap-12 justify-start">
          <div className="flex gap-12">
            <div>
              <Delete />
            </div>
            <div className="text-[20rem] leaning-[28rem] font-medium text-white">
              You want to block this address?
            </div>
          </div>
          <div className="text-[14rem] leaning-[18rem] text-vitreus-black-100">
            {`${address.slice(0, 6)}...${address.slice(-4)}`}
          </div>
        </div>
        <GradientButton
          className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium"
          text={"block"}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};
