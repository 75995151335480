export enum Period {
  Day = "day",
  Week = "week",
  Month = "month",
}

export enum PeriodTopMembers {
  Week = "week",
  Month = "month",
  AllTime = "allTime",
}
