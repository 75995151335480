import cx from "classnames";
import { FC } from "react";

export const Address: FC<{ address: string; isShort?: boolean; className?: string }> = ({
  address,
  isShort = false,
  className,
}) => {
  return address && address.length > 10 ? (
    <span className={cx("text-green ml-5", className)}>
      {isShort
        ? `${address.slice(0, 4)}...${address.slice(-4)}`
        : `${address.slice(0, 4)}...${address.slice(-6)}`}
    </span>
  ) : (
    <></>
  );
};
