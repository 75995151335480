import { ChartPointDto } from "@fe/app/pages/statistics/requests";
import {
  Chart as ChartJS,
  ChartOptions,
  InteractionAxis,
  registerables,
  TooltipItem,
} from "chart.js";
import moment from "moment";
import React from "react";
import { Chart } from "react-chartjs-2";

import { ChartValue, formatChartData, formatNumber, Period } from "../../helpers";

interface ChartWrapperProps {
  data: ChartPointDto[];
  period: Period;
  title: string;
  subtitle?: string;
}

export const ChartWrapper: React.FC<ChartWrapperProps> = ({ period, title, subtitle, data }) => {
  const chartPoints = data ? formatChartData({ data: data, period }) : [];

  return chartPoints.length ? (
    <div className="flex flex-col gap-24 bg-vitreus-green-800 px-24 py-32 rounded-[20rem] sm:!p-8">
      <div className="flex gap-24 items-baseline sm:flex-col sm:gap-8">
        <div className="flex gap-8 text-[20rem] leading-[28rem] sm:text-[12rem] sm:leading-[16rem]">
          <h5 className="text-white uppercase">{title}</h5>
          <span>{`(${subtitle})`}</span>
        </div>
        <span className="text-white text-16 leading-24 sm:text-[12rem] sm:leading-[16rem]">
          {`${moment(chartPoints[0].label).utc().format("llll")} - ${moment(
            chartPoints[chartPoints.length - 1].label,
          )
            .utc()
            .format("llll")}`}
        </span>
      </div>

      <ChartBlock data={chartPoints} title={title} />
    </div>
  ) : null;
};

export interface ChartBlockProps {
  data: ChartValue[];
  title: string;
}

export const ChartBlock: React.FC<ChartBlockProps> = ({ data, title }) => {
  ChartJS.register(...registerables);

  const isMobile = window.innerWidth <= 600;

  const options: ChartOptions = {
    responsive: true,
    aspectRatio: isMobile ? 1 / 1 : 3 / 1,

    interaction: {
      intersect: false,
      axis: "x" as InteractionAxis,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleColor: "#EEF2F2",
        backgroundColor: "#223B39",
        bodyColor: "#EEF2F2",
        titleFont: {
          size: isMobile ? 8 : 12,
          weight: "500",
          family: "DM Sans",
        },
        bodyFont: {
          size: isMobile ? 12 : 16,
          weight: "400",
          family: "DM Sans",
        },
        titleSpacing: 100,
        bodySpacing: isMobile ? 2 : 5,
        boxPadding: isMobile ? -14 : -18,
        padding: isMobile ? 4 : 12,
        caretPadding: 10,
        caretSize: 10,
        yAlign: "bottom",
        callbacks: {
          afterLabel: function (context: TooltipItem<"bar">) {
            return `${title}: ${formatNumber(data[context.parsed.x].mainValue)}`;
          },
          label: function () {
            return "";
          },
          title: function (items: TooltipItem<"bar">[]) {
            return items.map((point) => `${moment(point.label).format("dddd DD, MMMM, yyyy")}`);
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: " #EEF2F2",
        },
        display: true,
        offset: true,

        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        offset: true,
        border: {
          display: false,
        },

        ticks: {
          color: " #EEF2F2",
        },

        suggestedMin: 0,

        grid: {
          display: true,
          color: "#748887",
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      options={options}
      data={{
        labels: data.map((el) => moment(el.label).utc().format("L")),

        datasets: [
          {
            data: data.map((el) => el.mainValue),
            backgroundColor: "#6DF1B2",
            hoverBackgroundColor: "#EFF16D",
            barPercentage: isMobile ? 0.5 : 0.2,
            borderRadius: 15,
            // pointBorderWidth: 0,
            // pointRadius: 0,
            // pointBackgroundColor: "#6DF1B2",
            // pointBorderColor: "white",
            // pointHoverBackgroundColor: "#6DF1B2",
            // pointHoverBorderColor: "white",
            // pointHoverBorderWidth: isMobile ? 4 : 8,
            // pointHoverRadius: isMobile ? 8 : 16,
          },
        ],
      }}
    />
  );
};
