// TODO: remove to vit-ui-kit
export { ReactComponent as VitreusLogo } from "./logo.svg";
export { ReactComponent as UserLight } from "./ph_user-light.svg";
export { ReactComponent as ArrowGrowth } from "./u_arrow-growth.svg";
export { ReactComponent as ArrowLeft } from "./u_arrow-left.svg";
export { ReactComponent as ArrowFalling } from "./u_chart-down.svg";
export { ReactComponent as Check } from "./u_check.svg";
export { ReactComponent as Clock } from "./u_clock-three.svg";
export { ReactComponent as ColorDocIcon } from "./u_color-doc.svg";
export { ReactComponent as Copy } from "./u_copy.svg";
export { ReactComponent as Cube } from "./u_cube.svg";
export { ReactComponent as DocIcon } from "./u_doc.svg";
export { ReactComponent as Error } from "./u_error.svg";
export { ReactComponent as LogoMini } from "./u_logo-mini.svg";
export { ReactComponent as Question } from "./u_question-circle.svg";
export { ReactComponent as RoundCheck } from "./u_round-check.svg";
export { ReactComponent as SandClock } from "./u_sand-clock.svg";
export { ReactComponent as Magnifier } from "./u_search.svg";
