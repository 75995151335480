import "./styles.scss";

import { RouterLinks } from "@fe/app/constants/router-links";
import { sidebarLink } from "@fe/app/constants/sidebar";
import { useUser } from "@fe/app/hooks/useUser";
import { BackgroundBlur, SideBar } from "@fe/app/ui-kit";
import cx from "classnames";
import { FC, ReactElement } from "react";
import { useLocation } from "react-router-dom";

import { Header } from "../header";

export const Layout: FC<{ children: ReactElement }> = ({ children }) => {
  const { user } = useUser();
  const location = useLocation();

  return (
    <div className="bg-[#0e1818] font-sans font-p1 font-normal tracking-normal text-gray max-h-screen main-layout flex flex-col w-screen h-screen">
      <Header />

      <div className="w-full flex">
        {user && <SideBar links={sidebarLink} className="" />}

        {/* TODO: improve logic with mt-[82px] ml-[138px] */}
        <div
          className={cx("absolute top-0 right-0 bottom-0 left-0 pt-[82px] sm:pl-[0rem]", {
            "pl-[138px]": user,
          })}
        >
          {location.pathname !== RouterLinks.Home && <BackgroundBlur />}
          <div className="content relative z-10 w-full h-full overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};
