import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { Roles } from "../constants/roles";
import { Api } from "../helpers/Api";

export interface InvitedAffiliateDto {
  _id: string;
  address: string;
  isBlocked: boolean;
  role: {
    _id: string;
    role: Roles;
  };
}

export interface InvitedAffiliate {
  _id: string;
  address: string;
  isBlocked: boolean;
  role: Roles;
}

export interface InvitedAffiliateResponse {
  users: InvitedAffiliate[];
  count: number;
}

export const useGetInvitedAffiliates = ({
  limit,
  offset,
}: {
  limit: number;
  offset: number;
}): UseQueryResult<InvitedAffiliateResponse> =>
  useQuery(["useGetInvitedAffiliates"], async () => {
    const response = await Api.get<{ count: number; users: InvitedAffiliateDto[] }>(
      "/user/all",
      "json",
      {
        limit: limit,
        offset: offset,
      },
    );

    return {
      count: response.count,
      users: response.users.map(({ role, ...rest }) => ({
        ...rest,
        name: rest.address,
        role: role.role,
      })),
    };
  });
