import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { Api } from "../helpers/Api";

export interface GetSignatureResponse {
  signatureEIP712: "string";
  _id: "string";
  address: "string";
}

export const useGetSignature = (): UseQueryResult<{
  signatureEIP712: "string";
  _id: "string";
  address: "string";
}> =>
  useQuery(["useGetSignature"], async () => {
    return await Api.get("/user/affiliate-signature");
  });
