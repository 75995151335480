/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { SmartContract, useAddress, useSDK } from "@thirdweb-dev/react";
import { BaseContract, BigNumber, Contract, providers, Signer, utils } from "ethers/lib/ethers";

import payoutJSON from "../abi/AffiliatePayoutRegistry.json";
import { config } from "../config/environment.config";
import { Roles } from "../constants";
import { useUser } from "./useUser";

interface ContractTypes {
  contract: Contract;
  needToDeposit?: string;
  handleDeposit?: (smartContract: SmartContract<BaseContract>) => Promise<any>;
  affiliateDeposits?: string;
  affiliateRewards?: string;
  affiliateClaimed?: string;
  totalDeposits?: string;
  totalRewards?: string;
  totalClaimed?: string;
  claimRewards?: (signature: string, smartContract: SmartContract<BaseContract>) => Promise<any>;
}
const gasMult = 1.2;

const gasLimitHelper =
  (contract: SmartContract<BaseContract>, value: any) =>
  async (fn: string, customGasLimit?: number | string, args?: any[]) => {
    try {
      const gasLimit = await contract.estimator.gasLimitOf(fn, [...(args ? args : []), {}]);

      return Math.floor(
        Math.max(+utils.formatUnits(gasLimit, 0) * gasMult, +(customGasLimit || 0)),
      ).toString();
    } catch (error) {
      return customGasLimit?.toString() || "0";
    }
  };

const useContract = (
  signer?: Signer,
  address?: string,
  provider?: providers.Provider,
): UseQueryResult<ContractTypes | null> => {
  const { user } = useUser();

  const isAdmin = user?.role === Roles.Admin;

  return useQuery(
    ["getContract", address],
    async () => {
      if (!address?.length) {
        return null;
      }
      // CONTRACT
      const contract = new Contract(config.AFFILIATE_CONTRACT, payoutJSON.abi, signer);

      // ROLES CHECK
      const operatorId = await contract.OPERATOR_ROLE();

      const isOperator = operatorId && (await contract.hasRole(operatorId, address));

      // AFFILIATE DATA
      const affiliateDeposits =
        !isAdmin && !isOperator && (await contract.accumulatedDeposits(address));

      const affiliateRewards =
        !isAdmin && !isOperator && (await contract.getAffiliateRewards(address));
      const affiliateClaimed = !isAdmin && !isOperator && (await contract.claimedAmount(address));

      const claimRewards = async (
        signature: string,
        smartContract: SmartContract<BaseContract>,
      ) => {
        if (!isAdmin && !isOperator && !!affiliateRewards) {
          const estimateLimit = gasLimitHelper(smartContract, affiliateRewards);

          await estimateLimit("claimRewards", config.CLAIM_GAS_LIMIT, [signature]);
          const response = await smartContract.call("claimRewards", [signature], {
            gasLimit: await estimateLimit("claimRewards", config.CLAIM_GAS_LIMIT, [signature]),
          });
          return response;
        }
      };

      // ADMIN DATA
      const needToDeposit = (isAdmin || isOperator) && (await contract.getPendingRewards());
      const totalDeposits = (isAdmin || isOperator) && (await contract.getTotalAffiliateDeposits());
      const totalRewards = (isAdmin || isOperator) && (await contract.getTotalAffiliateRewards());
      const totalClaimed = (isAdmin || isOperator) && (await contract.totalClaimed());

      // OPERATOR DATA

      const handleDeposit = async (smartContract: SmartContract<BaseContract>) => {
        if (isOperator) {
          const estimateLimit = gasLimitHelper(smartContract, needToDeposit);

          await estimateLimit("depositFunds", config.DEPOSIT_GAS_LIMIT);

          const response = await smartContract.call("depositFunds", [], {
            gasLimit: await estimateLimit("depositFunds", config.DEPOSIT_GAS_LIMIT),
          });
          return response;
        }
      };

      return isOperator
        ? {
            contract,
            needToDeposit: needToDeposit ? utils.formatUnits(needToDeposit, 6) : 0,
            totalDeposits: totalDeposits ? utils.formatUnits(totalDeposits, 6) : 0,
            totalRewards: totalRewards ? utils.formatUnits(totalRewards, 6) : 0,
            totalClaimed: totalClaimed ? utils.formatUnits(totalClaimed, 6) : 0,
            handleDeposit,
          }
        : isAdmin
        ? {
            contract,
            needToDeposit: needToDeposit ? utils.formatUnits(needToDeposit, 6) : 0,
            totalDeposits: totalDeposits ? utils.formatUnits(totalDeposits, 6) : 0,
            totalRewards: totalRewards ? utils.formatUnits(totalRewards, 6) : 0,
            totalClaimed: totalClaimed ? utils.formatUnits(totalClaimed, 6) : 0,
          }
        : {
            affiliateDeposits: affiliateDeposits ? utils.formatUnits(affiliateDeposits, 6) : 0,
            affiliateRewards: affiliateRewards ? utils.formatUnits(affiliateRewards, 6) : 0,
            affiliateClaimed: affiliateClaimed ? utils.formatUnits(affiliateClaimed, 6) : 0,
            claimRewards,
          };
    },
    { enabled: !!signer },
  );
};

export const useClaimContract = () => {
  const sdk = useSDK();
  const address = useAddress();
  const signer = sdk?.getSigner();
  const provider = sdk?.getProvider();

  const {
    data: claimData,
    isLoading: isClaimContractLoading,
    error: claimContractError,
    refetch: refetchClaim,
  } = useContract(signer, address, provider);

  return {
    claimData,
    isClaimContractLoading,
    claimContractError,
    refetch: () => {
      refetchClaim();
    },
  };
};
