import "./ConnectWalletBtn.styles.scss";

import { ConnectWallet, useAddress, useConnectionStatus } from "@thirdweb-dev/react";
import cx from "classnames";
import { GradientButton } from "vit-ui-kit";

import { Address } from "../address";
import { UserLight } from "../icons";

export interface ButtonProps {
  text: string;
  className?: string;
  rounded?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const ConnectWalletBtn: React.FC<Omit<ButtonProps, "text">> = ({
  className,
  onClick,
  fullWidth,
}) => {
  const connectionStatus = useConnectionStatus();
  const address = useAddress();

  if (connectionStatus === "connected" && address) {
    return (
      <div className="sm:ml-auto">
        <ConnectWallet
          detailsBtn={() => (
            <button
              className={cx(
                "flex items-center px-16 py-8 gap-16 bg-black rounded-full transition-colors duration-300 hover:bg-vitreus-green-700 text-black sm:!px-4 sm:gap-4",
                className,
              )}
              style={{ position: "relative" }}
            >
              <Address address={address} className="!text-gray" isShort={true} />
              <UserLight />
            </button>
          )}
        />
      </div>
    );
  }

  return (
    <div className="connectHeader" style={{ position: "relative" }}>
      <ConnectWallet
        btnTitle=" "
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
        }}
      />

      <GradientButton
        className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium w-fit sm:h-[30px]"
        text={"Connect wallet"}
      />
    </div>
  );
};

export default ConnectWalletBtn;
