import { FC } from "react";
import { StrokeButton } from "vit-ui-kit";

export const GoToTopButton: FC = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <StrokeButton
      className="top-[-82px] 2xl:top-[-50px] right-[40px] absolute"
      onClick={handleClick}
      text={"GO TO TOP"}
    />
  );
};
