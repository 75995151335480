import { useAddress, useConnectionStatus, useSigner, useWalletContext } from "@thirdweb-dev/react";
import jwtDecode from "jwt-decode";
import { createContext, FC, ReactElement, useContext, useEffect, useState } from "react";

import { useLogin } from "../actions/useLogin";
import { useLogout } from "../actions/useLogout";
import { Roles } from "../constants/roles";
import { AppStorage } from "../helpers/Storage";

export interface User {
  address: string;
  clientId: string;
  exp: number;
  iat: number;
  role: Roles;
  isBlocked: boolean;
}

export interface AuthProps {
  user: User | null;
  isLoading: boolean;
  login: () => void;
  error: string;
  isError: boolean;
}

const AuthContext = createContext<AuthProps>({
  isLoading: true,
  login: () => {
    //
  },
  user: null,
  error: "",
  isError: false,
});

export const useUser = () => useContext(AuthContext);

// TODO: make something with token expiration time and refresh token
export const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const tokens = AppStorage.get("tokens");
  const [user, setUser] = useState<User | null>(() =>
    tokens ? jwtDecode(tokens.accessToken) : null,
  );

  const [error, setError] = useState<string>("");

  const status = useConnectionStatus();
  const address = useAddress();
  const signer = useSigner();
  const context = useWalletContext();

  const { isLoading: isLoginLoading, mutate: login, isError } = useLogin(signer, setUser, setError);
  const { isLoading: isLogoutLoading, mutate: logout } = useLogout(setUser);

  useEffect(() => {
    if (status === "disconnected") {
      logout();
    }
  }, [status]);

  useEffect(() => {
    if (user && address && user.address !== address) {
      logout();
    }
  }, [address]);

  useEffect(() => {
    const logoutHandler = () => logout();

    context.activeWallet?.addListener("change", logoutHandler);
    context.activeWallet?.addListener("disconnect", logoutHandler);

    return () => {
      context.activeWallet?.removeListener("change", logoutHandler);
      context.activeWallet?.removeListener("disconnect", logoutHandler);
    };
  }, [context]);

  // useEffect(() => {
  //   if ((user && status === "connected") || status === "disconnected") {
  //     logout();
  //   }
  // }, [address, status]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading: isLoginLoading || isLogoutLoading,
        login,
        error: error,
        isError: isError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
