import { socialLinks } from "@fe/app/constants/social-links";
import { VitreusLogo } from "@fe/app/ui-kit/icons";
import { ConnectWallet } from "@thirdweb-dev/react";
import { GradientButton } from "vit-ui-kit";

export const ConnectWalletWindow = () => {
  return (
    <>
      <VitreusLogo className="w-64 h-64" />
      <h2 className="text-white text-[20px] text-center w-[250px]">
        {"Welcome to VITREUS AFFILIATE PROGRAM".toUpperCase()}
      </h2>
      <p className="w-[200px] text-center mb-32">Let’s get started! Please, connect your wallet.</p>
      <div className="relative w-full">
        <ConnectWallet
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0,
          }}
        />
        <GradientButton
          aria-label="Connect Wallet"
          data-test="connect-wallet-button"
          className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium w-full"
          text="Connect wallet"
        />
      </div>
      <div className="flex w-full justify-around mt-24">
        {socialLinks.map(({ link, icon }) => (
          <a key={link} target="_blank" rel="noreferrer" href={link} className="">
            {icon}
          </a>
        ))}
      </div>
      {/* <SocialMediaLinks /> */}
    </>
  );
};
