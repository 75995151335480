import "./styles.scss";

import { SideBarLinkProps } from "@fe/app/constants/sidebar";
import { useUser } from "@fe/app/hooks";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface NavLinkProps {
  sidebarOption: SideBarLinkProps;
}

export interface SidebarProps {
  links: SideBarLinkProps[];
  className?: string;
}

export const HeaderLinks: React.FC<SidebarProps> = ({ links, className }) => {
  const { user } = useUser();

  if (!user) {
    return null;
  }

  return (
    <ul className={`hidden gap-8 hidden sm:flex ${className}`}>
      {links
        .filter(({ roles }) => roles.includes(user.role))
        // .filter(({ roles }) => roles.includes(Roles.Admin))
        .map((element) => (
          <NavLink key={element.link} sidebarOption={element} />
        ))}
    </ul>
  );
};

export const NavLink: React.FC<NavLinkProps> = ({ sidebarOption }) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { link, image } = sidebarOption;

  const isActive = pathname === link;

  const handleClick = () => {
    navigate(`..${link}`);
  };

  return (
    <button
      onClick={handleClick}
      className="flex flex-col wrapper gap-8 items-center cursor-pointer rounded-[12rem] "
    >
      <div
        className={`icon-wrapper ${isActive ? "active" : ""} ${
          isActive ? "bg-gradient-to-b  from-[#6DF1B2] to-[#1CC5F9]" : ""
        } p-12 rounded-[12rem] sm:p-[6rem]`}
      >
        {image}
      </div>
    </button>
  );
};
