import "./config/i18n.config";

import { QueryClientProvider } from "@tanstack/react-query";
import { Ethereum } from "@thirdweb-dev/chains";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { IpfsUploader, StorageDownloader, ThirdwebStorage } from "@thirdweb-dev/storage";
import { RouterProvider } from "react-router-dom";

import { config } from "./config/environment.config";
import { queryClient } from "./config/queryClient.config";
import { appWallets } from "./config/wallets.config";
import { AuthProvider } from "./hooks/useUser";
import router from "./router";

const App = () => {
  const downloader = new StorageDownloader({
    secretKey:
      "xexVxhGnVhgyH-G0QXeye16jYWdeShTQ00HtfjZtAyVxIjhkH1xVfLTBVe4UrBUu4Ka_gVZL8CfOMoU7ngUnAw",
  });
  const uploader = new IpfsUploader({ uploadWithGatewayUrl: true });
  const storage = new ThirdwebStorage({
    uploader,
    downloader,
    gatewayUrls: [
      "https://w3s.link/ipfs/",
      "https://gateway.ipfscdn.io/ipfs/",
      "https://cloudflare-ipfs.com/ipfs/",
      "https://ipfs.io/ipfs/",
    ],
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThirdwebProvider
        dAppMeta={{
          name: "Vitreus Affiliate dApp",
          logoUrl:
            "https://bafybeif23wbgdywp55lyj3p7os2bzphuwyuhrq53f3jz5hu7aq6pgmukam.ipfs.w3s.link/vitreus-logo.png",
          url: "https://vam.vitreus.io/",
          description: "Vitreus Affiliate dApp",
        }}
        activeChain={config.activeChain}
        supportedChains={[Ethereum]}
        storageInterface={storage}
        clientId={config.twClientId}
        supportedWallets={appWallets}
        autoSwitch
      >
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ThirdwebProvider>
    </QueryClientProvider>
  );
};

export default App;
