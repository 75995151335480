import "./styles.scss";

import { useGetStatistics } from "@fe/app/actions/useGetStatistics";
import { Roles } from "@fe/app/constants/roles";
import { useUser } from "@fe/app/hooks";
import { useClaimContract } from "@fe/app/hooks/useContract";
import { PageTitle } from "@fe/app/ui-kit/page-title";
import { ColumnDef } from "@tanstack/react-table";
import { FC, ReactElement } from "react";
import { Table, TableDataType } from "vit-ui-kit";

import {
  GeneratedRevenue,
  MembersBlock,
  StatisticChartBlock,
  TopMembersBlock,
  WithdrawBLock,
} from "./components";
import { columnNames as tempColumnNames } from "./utils/temp-columns-settings";

const BlurWrapper: FC<{
  children: ReactElement | ReactElement[] | null;
  modalContent?: ReactElement | ReactElement[] | null;
  isShow?: boolean;
}> = ({ children, modalContent, isShow }) => {
  if (!isShow) {
    return <>{children}</>;
  }

  return (
    <div className="relative ">
      <div className="blur">{children}</div>
      {modalContent && (
        <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] p-20 shadow-md sm:w-[80%]">
          {modalContent}
        </div>
      )}
    </div>
  );
};

export const StatisticsPage = () => {
  const { data } = useGetStatistics();
  const { user } = useUser();
  const { claimData, refetch } = useClaimContract();

  return (
    <div className="my-40 mx-20">
      <BlurWrapper
        isShow={user?.isBlocked}
        modalContent={
          <div className="flex gap-12 items-center justify-center w-full  ">
            <h1 className="text-[20px] text-white text-center">
              {"You are blocked".toUpperCase()}
            </h1>
          </div>
        }
      >
        {user && user.role === Roles.User ? (
          <WithdrawBLock
            claim={claimData?.claimRewards}
            claimed={claimData?.affiliateClaimed}
            pendingRewards={claimData?.affiliateRewards}
            refetch={refetch}
          />
        ) : (
          <></>
        )}

        <PageTitle text="DEPOSITS" />

        <div className="w-full overflow-x-auto rounded-large border-vitreus-green-800 border max-h-[500rem] px-20 mb-40">
          {data?.length ? (
            <Table
              data={data as unknown as TableDataType[]}
              columns={tempColumnNames as unknown as ColumnDef<TableDataType, string>[]}
              className="table center text-p1 w-full"
            />
          ) : (
            <span>No deposits</span>
          )}
        </div>

        <div className="grid w-full grid-cols-3 gap-32 mb-32 xl:grid-cols-1">
          <GeneratedRevenue />
          <MembersBlock />
          <TopMembersBlock />
        </div>
        <StatisticChartBlock />
      </BlurWrapper>
    </div>
  );
};
