import { config } from "@fe/app/config/environment.config";
import { useUser } from "@fe/app/hooks";
import { Error, RoundCheck } from "@fe/app/ui-kit/icons";
import { useNetworkMismatch, useSwitchChain } from "@thirdweb-dev/react";
import { useEffect } from "react";
import { GradientButton } from "vit-ui-kit";

export const LoginWindow = () => {
  const { login, user, error, isError } = useUser();
  const isMismatched = useNetworkMismatch();
  const switchChain = useSwitchChain();

  useEffect(() => {
    //
  }, [error, user]);

  const handleLogin = async () => {
    login();
  };

  const handleSwitch = () => {
    if (isMismatched) {
      switchChain(+config.ACTIVE_CHAIN_ID);
    }
  };

  return (
    <>
      {error.length || isError ? (
        <>
          <Error className="w-40 h-40 mb-28" />
          <h2 className="text-white text-[20px] text-center w-[250px]">
            {"Error during login".toUpperCase()}
          </h2>
          <p className="w-[200px] text-center mb-32">
            {error.length && typeof error === "string"
              ? error.charAt(0).toUpperCase() + error.slice(1)
              : "Request error! Check your network connection and try to turn off your ad blocker extension."}
          </p>
        </>
      ) : (
        <>
          <RoundCheck className="w-40 h-40 mb-28" />
          <h2 className="text-white text-[20px] text-center w-[250px]">
            {"Wallet connected successfully".toUpperCase()}
          </h2>
          <p className="w-[200px] text-center mb-32">Please, login to platform</p>
        </>
      )}

      <GradientButton
        className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium w-full"
        text={isMismatched ? "Switch Network" : "Login"}
        onClick={isMismatched ? handleSwitch : handleLogin}
      />
    </>
  );
};
