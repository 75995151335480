import cx from "classnames";
import { FC, ReactElement, useState } from "react";

import { StatisticsTopBlock } from ".";

export const StatisticsTopBlockWithTabs: FC<{
  children: ReactElement[];
  tabs: string[];
  className?: string;
  key?: string;
}> = ({ children, className, key, tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <StatisticsTopBlock key={key} className={cx("!pb-0 flex flex-col justify-between", className)}>
      <div>{children[currentTab]}</div>
      <div className="w-full flex justify-between gap-24">
        {tabs.map((tab, index) => (
          <button
            key={tab}
            className={cx(
              "border-b-2 h-[66px] w-full text-[12px]",
              {
                "text-green border-[#6DF1B2]": currentTab === index,
              },
              { "border-transparent": currentTab !== index },
            )}
            onClick={() => setCurrentTab(index)}
          >
            {tab.toUpperCase()}
          </button>
        ))}
      </div>
    </StatisticsTopBlock>
  );
};
