import { ReactComponent as Discord } from "../../assets/images/discord.svg";
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
import { ReactComponent as Linkedin } from "../../assets/images/linkedin.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as YouTube } from "../../assets/images/youtube.svg";

export const socialLinks = [
  {
    icon: <Facebook />,
    link: "https://www.facebook.com/VitreusChain",
  },
  {
    icon: <Linkedin />,
    link: "https://www.linkedin.com/company/vitreus-chain",
  },
  {
    icon: <YouTube />,
    link: "https://www.youtube.com/@VitreusChain",
  },
  {
    icon: <Twitter />,
    link: "https://x.com/vitreuschain",
  },
  {
    icon: <Telegram />,
    link: "https://t.me/VitreusChain",
  },
  {
    icon: <Discord />,
    link: "https://discord.com/invite/Vitreus",
  },
];
