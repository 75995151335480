import { environment } from "@fe/environments/environment";

export const config = {
  isDev: environment.environment,
  affiliateApiUrl: environment.affiliateApiUrl,
  twClientId: environment.THIRD_WEB_CLIENT_ID,
  activeChain: environment.ACTIVE_CHAIN,
  SENTRY_DSN: environment.SENTRY_DSN,
  ACTIVE_CHAIN_ID: environment.ACTIVE_CHAIN_ID,
  AFFILIATE_CONTRACT: environment.AFFILIATE_CONTRACT,
  USDC_ADDRESS: environment.USDC_ADDRESS,
  CLAIM_GAS_LIMIT: environment.CLAIM_GAS_LIMIT,
  DEPOSIT_GAS_LIMIT: environment.DEPOSIT_GAS_LIMIT,
  ONFIDO: environment.ONFIDO,
};
