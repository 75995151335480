import "./generate-link-button.styles.scss";

import { useUser } from "@fe/app/hooks/useUser";
import { ModalWindow } from "@fe/app/ui-kit/modal-window";
import React, { useState } from "react";
import { GradientButton } from "vit-ui-kit";

import { GenerateLink } from "./generate-link";

export const GenerateLinkButton = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  if (!user) {
    return null;
  }

  const handleOpen = () => {
    !isOpen && setIsOpen(true);
  };

  const handleClose = () => {
    isOpen && setIsOpen(false);
  };

  return (
    <>
      <GradientButton
        disabled={isOpen}
        className="linkButton bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium sm:hidden "
        text={"generate link"}
        onClick={handleOpen}
      />

      <GradientButton
        disabled={isOpen}
        className="hidden linkButton bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium sm:flex"
        text={"generate"}
        onClick={handleOpen}
      />
      {isOpen && (
        <ModalWindow closeModal={handleClose} className="absolute py-[100rem] top-0">
          <GenerateLink handleClose={handleClose} />
        </ModalWindow>
      )}
    </>
  );
};
