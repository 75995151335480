import { Statistics } from "@fe/app/actions/useGetStatistics";
import { round } from "@fe/app/helpers";
import { Address } from "@fe/app/ui-kit/address";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { cellTypes } from "vit-ui-kit";

export const columnNames: ColumnDef<Statistics, string>[] = [
  {
    header: "Address",
    cell: (info) => <Address address={info.getValue()} />,
    accessorKey: "buyer",
  },
  {
    header: "Deposit",
    cell: (info) => <span className="text-white">{round(Number(info.getValue()), 2)}</span>,
    accessorKey: "deposit",
  },
  {
    header: "Affiliate",
    cell: (info) => <Address address={info.getValue()} />,
    accessorKey: "inviter",
  },
  {
    header: "Round",
    cell: (info) => <cellTypes.text {...info} className="text-white" />,
    accessorKey: "round",
  },
  {
    header: "TokenId",
    cell: (info) => <cellTypes.text {...info} className="text-white" />,
    accessorKey: "tokenId",
  },
  {
    header: "Timestamp",
    cell: (info) => (
      <cellTypes.text
        {...info}
        getValue={() => moment(info.getValue()).format("MM.DD.YYYY hh:mm A UTC")}
        className="text-white"
      />
    ),
    accessorKey: "timestamp",
  },
];
