import cx from "classnames";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Copy } from "../icons";
import { Tooltip } from "../tooltip";

// TODO: rebase to vit-ui-kit
export const CopyText: FC<{ text: string }> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const copyContent = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }, []);

  return (
    <>
      <Copy
        data-tooltip-id={`anchor-copy-${text}`}
        data-tooltip-content={isCopied ? t("copied_tooltip") : t("copy_tooltip")}
        onClick={copyContent}
        className={cx("cursor-pointer min-w-[24px] min-h-[24px] w-24 h-24", `anchor-copy-${text}`)}
      />
      <Tooltip
        name={`anchor-copy-${text}`}
        tooltip={isCopied ? t("copied_tooltip") : t("copy_tooltip")}
      />
    </>
  );
};
