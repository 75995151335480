import { useMutation } from "@tanstack/react-query";
import { Dispatch, SetStateAction } from "react";

import { AppStorage } from "../helpers/Storage";
import { User } from "../hooks/useUser";

export const useLogout = (setUser?: Dispatch<SetStateAction<User | null>>) =>
  useMutation(async () => {
    AppStorage.remove("tokens");
    setUser?.(null);
  });
