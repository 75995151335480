import { Period } from "@fe/app/constants";
import { round } from "@fe/app/helpers";
import {
  ContentInTab,
  ContentInTabProps,
} from "@fe/app/ui-kit/statistics-top-blocks-wrapper/coontent-in-tab";
import { StatisticsTopBlockWithTabs } from "@fe/app/ui-kit/statistics-top-blocks-wrapper/statistics-top-blocks-wrapper-with-tabs";
import React from "react";
import { VitreusLoader } from "vit-ui-kit";

import { useGetGeneratedRevenue } from "../../requests";

const periodFormatter: { [key in Period]: string } = {
  day: `24 hour revenue`,
  week: `7 day revenue`,
  month: `30 day revenue`,
};

export const GeneratedRevenue: React.FC = () => {
  const { data: dayData, isLoading: loadingDay } = useGetGeneratedRevenue({ period: Period.Day });
  const { data: weekData, isLoading: loadingWeek } = useGetGeneratedRevenue({
    period: Period.Week,
  });
  const { data: monthData, isLoading: loadingMonth } = useGetGeneratedRevenue({
    period: Period.Month,
  });

  if (loadingDay || loadingWeek || loadingMonth) {
    return (
      <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
    );
  }

  if (!monthData?.generatedRevenue && !weekData?.generatedRevenue && !dayData?.generatedRevenue) {
    return null;
  }

  const formattedData: ContentInTabProps[] = [dayData, weekData, monthData].reduce((acc, curr) => {
    const data: ContentInTabProps = {
      title: "Generated revenue",
      titleAddition: "(USD)",
      subTitle: `Total revenue: ${round(Number(curr?.totalRevenue), 2)}$`,
      mainNumber: `$${round(Number(curr?.generatedRevenue), 2)}`,
      periodName: periodFormatter[curr?.period || Period.Day],
      percentage: curr?.revenueDiff ? +(curr?.revenueDiff * 100).toFixed(2) : 0,
    };

    acc.push(data);
    return acc;
  }, [] as ContentInTabProps[]);

  return (
    <StatisticsTopBlockWithTabs tabs={["today", "this week", "this month"]}>
      {formattedData.map((content) => (
        <ContentInTab key={content?.periodName} {...content} />
      ))}
    </StatisticsTopBlockWithTabs>
  );
};
