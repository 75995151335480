import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { ModalWindowProps } from "./modal-window.model";

// TODO: rebase to vit-ui-kit

export const ModalWindow: React.FC<ModalWindowProps> = ({
  children,
  transparency,
  alignItems,
  justifyContent,
  className,
  closeModal,
}) => {
  const [isClosing, setClosing] = useState(false);
  const handleClose = () => {
    if (closeModal) {
      setClosing(true);
      closeModal();
    }
  };

  const content = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    document.body.style.overflow = `hidden`;

    return () => {
      document.body.style.overflow = `auto`;
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className={`z-[9999] fixed m-0 top-0 w-full h-full  bg-black/50 flex justify-center items-center overflow-scroll`}
    >
      <div className="z-[9999] w-full h-full absolute" onClick={handleClose} />
      <div ref={content} className={`w-fit z-[10000] ${className}`}>
        {children}
      </div>
    </div>,
    document.body,
  );
};
