import { PeriodTopMembers } from "@fe/app/constants";
import { Api } from "@fe/app/helpers/Api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export interface TopMembersDto {
  address: string;
  revenue: number;
}

export interface TopMembersResponse {
  data: TopMembersDto[];
  period: PeriodTopMembers;
}

export const useGetTopMembers = ({
  period,
}: {
  period: PeriodTopMembers;
}): UseQueryResult<TopMembersResponse> =>
  useQuery([`queryTopMembers${period}`], async () => {
    const response = await Api.get<TopMembersDto[]>(`/analytics/top-members?period=${period}`);

    return { data: [...response], period: period };
  });
