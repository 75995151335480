import { Api } from "@fe/app/helpers/Api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export interface ChartPointDto {
  revenue: number;
  timestamp: string;
}

export const useGetChartData = (): UseQueryResult<ChartPointDto[]> =>
  useQuery(["queryChartPoints"], async () => {
    const response = await Api.get<ChartPointDto[]>(`/analytics/earnings-graphic-points`);

    return response;
  });
