import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowLeft } from "../icons";
import { PageTitleProps } from "./page-title.model";

export const PageTitle: FC<PageTitleProps> = ({ text, withRollback, icon }) => {
  const navigate = useNavigate();

  const rollback = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div className="relative">
      {withRollback ? (
        <ArrowLeft
          className="absolute top-1/2 translate-y-[-50%] cursor-pointer"
          onClick={rollback}
        />
      ) : null}
      <h2 className="text-h5 font-bold text-center uppercase text-white tracking-widest mb-24">
        {icon && icon}
        {text}
      </h2>
    </div>
  );
};
