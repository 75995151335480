import { ArrowFalling, ArrowGrowth } from "@fe/app/ui-kit/icons";
import cx from "classnames";
import { FC } from "react";

export interface ContentInTabProps {
  title: string;
  titleAddition?: string;
  subTitle: string;
  mainNumber: string;
  periodName: string;
  percentage: number;
}

export const ContentInTab: FC<ContentInTabProps> = ({
  subTitle,
  mainNumber,
  periodName,
  percentage,
  title,
  titleAddition,
}) => {
  return (
    <div>
      <div className="flex items-end gap-10 mb-8">
        <h5 className="text-h5 text-white">{title.toLocaleUpperCase()}</h5>
        {titleAddition && <span>{titleAddition}</span>}
      </div>
      <p className="text-white text-p1 font-normal mb-32">{subTitle}</p>
      <h1 className="text-h1 font-normal text-white mb-16 sm:text-[40rem] sm:leaning-[45prem] ">
        {mainNumber}
      </h1>
      <p className="text-[#EEF2F2] mb-12">{periodName}</p>
      <div
        className={cx(
          "flex gap-16 mb-32",
          { "text-green": percentage > 0 },
          { "text-red": percentage < 0 },
        )}
      >
        {!percentage ? null : (
          <>
            {percentage > 0 ? <ArrowGrowth /> : <ArrowFalling />}
            <span>{Math.abs(percentage)}%</span>
          </>
        )}
      </div>
    </div>
  );
};
