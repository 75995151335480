import { useQuery, UseQueryResult } from "@tanstack/react-query";
import moment, { Moment } from "moment";

import { Api } from "../helpers/Api";

export interface StatisticResponseDto {
  count: number;
  data: StatisticsDto[];
}
export interface StatisticsDto {
  _id: string;
  address: string;
  affiliate: string;
  amount: string;
  round: number;
  timestamp: string;
  tokenId: number;
}

export interface Statistics {
  _id: string;
  buyer: string;
  deposit: number;
  round: number;
  tokenId: number;
  inviter: string;
  timestamp: Moment;
}

export const useGetStatistics = (): UseQueryResult<Statistics[]> =>
  useQuery(["useGetStatistics"], async () => {
    const response = await Api.get<StatisticResponseDto>("/deposit/all");

    if (!response.data) {
      return [];
    }

    return response.data.map(({ _id, address, affiliate, amount, round, timestamp, tokenId }) => {
      return {
        _id,
        buyer: address,
        deposit: +amount,
        round,
        tokenId,
        inviter: affiliate,
        timestamp: moment(timestamp).utc(),
      } as Statistics;
    });
  });
