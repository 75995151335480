import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { Api } from "../helpers/Api";

export const useGenerateLink = (): UseQueryResult<{
  qrCode: string;
  url: string;
  message: string;
}> =>
  useQuery(["useGenerateLink"], async () => {
    return await Api.get("/affiliate/url");
  });
