import { ReactComponent as AdminSvg } from "../../assets/images/admin.svg";
import { ReactComponent as HomeSvg } from "../../assets/images/home.svg";
import { Roles } from "./roles";
import { RouterLinks } from "./router-links";

export interface SideBarLinkProps {
  link: string;
  title: string;
  image: JSX.Element;
  roles: Roles[];
}

export const sidebarLink: SideBarLinkProps[] = [
  {
    link: RouterLinks.Statistics,
    title: "home",
    image: <HomeSvg />,
    roles: [Roles.Admin, Roles.User, Roles.Manager],
  },
  {
    link: RouterLinks.Admin,
    title: "admin",
    image: <AdminSvg />,
    roles: [Roles.Admin, Roles.Manager],
  },
];
