import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import { Roles } from "./constants/roles";
import { RouterLinks } from "./constants/router-links";
import { Layout } from "./layout/main-layout";
import { AdminPage } from "./pages/admin";
import { Initializer } from "./pages/initializer";
import { StatisticsPage } from "./pages/statistics";
import { RoleRoute } from "./ui-kit/RoleRoute";

export default createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: "/",
        element: <Initializer />,
      },
      {
        path: RouterLinks.Statistics,
        element: (
          <RoleRoute roles={[Roles.Admin, Roles.User, Roles.Manager]}>
            <StatisticsPage />
          </RoleRoute>
        ),
      },

      {
        path: RouterLinks.Admin,
        element: (
          <RoleRoute roles={[Roles.Admin, Roles.Manager]}>
            <AdminPage />
          </RoleRoute>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
