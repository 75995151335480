import "./export-button.styles.scss";

import { useExportCsv } from "@fe/app/actions/useExportCsv";
import { useCallback } from "react";

import { ReactComponent as Export } from "../../../assets/images/export.svg";

export const ExportButtonButton = () => {
  const { mutate } = useExportCsv();

  const handleExport = useCallback(() => mutate(), [mutate]);

  return (
    <button
      className="Export flex items-center gap-8 w-fit text-center py-[12rem] px-24 text-[12rem] rounded-[10rem] uppercase text-green border-[1rem] border-green hover:bg-green hover:text-black"
      onClick={handleExport}
    >
      <Export />
      export csv
    </button>
  );
};
