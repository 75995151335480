import { SideBarLinkProps } from "@fe/app/constants/sidebar";
import { useUser } from "@fe/app/hooks/useUser";
import cx from "classnames";
import React from "react";

import { NavLink } from "./components";

export interface SidebarProps {
  links: SideBarLinkProps[];
  className?: string;
}

export const SideBar: React.FC<SidebarProps> = ({ links, className }) => {
  const { user } = useUser();

  if (!user) {
    return null;
  }

  return (
    <ul className={cx("flex flex-col px-[35rem] py-24 gap-48 z-10 sm:!hidden", className)}>
      {links
        .filter(({ roles }) => roles.includes(user.role))
        .map((element) => (
          <NavLink key={element.link} sidebarOption={element} />
        ))}
    </ul>
  );
};
