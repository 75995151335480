import { useMutation } from "@tanstack/react-query";
import { Signer } from "ethers/lib/ethers";
import jwtDecode from "jwt-decode";
import { Dispatch, SetStateAction } from "react";

import { Api } from "../helpers/Api";
import { AppStorage } from "../helpers/Storage";
import { User } from "../hooks/useUser";

export const useLogin = (
  signer?: Signer,
  setUser?: Dispatch<SetStateAction<User | null>>,
  setError?: Dispatch<SetStateAction<string>>,
) =>
  useMutation(async () => {
    const data = await Api.post("/auth/login-message", {
      address: await signer?.getAddress(),
      chainId: await signer?.getChainId(),
    });

    if (data?.statusCode && (data?.statusCode as unknown) !== 201) {
      setError?.(`${data?.statusCode} ${data?.message}`);
    }

    if (!data || !data.message) {
      setError?.(
        "Request error! Check your network connection and try to turn off your ad blocker extension.",
      );
      return;
    }

    const signature = await signer?.signMessage(data.message || "");

    const tokens = await Api.post<{
      accessToken: string;
      refreshToken: string;
      error: string;
      message: string;
    }>("/auth/login", {
      address: await signer?.getAddress(),
      signature,
    });

    if (tokens.error && tokens.message) {
      setError?.(tokens.message);
    }

    if (tokens.accessToken) {
      setError?.("");
      AppStorage.add("tokens", tokens);
      setUser?.(jwtDecode(tokens.accessToken));
    }
  });
