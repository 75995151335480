import { PeriodTopMembers, Roles } from "@fe/app/constants";
import { useUser } from "@fe/app/hooks";
import { Loader } from "@fe/app/ui-kit/loader";
import { StatisticsTopBlockWithTabs } from "@fe/app/ui-kit/statistics-top-blocks-wrapper/statistics-top-blocks-wrapper-with-tabs";
import React from "react";
import { Table, TableDataType, VitreusLoader } from "vit-ui-kit";

import { useGetTopMembers } from "../../requests";
import { columnNames } from "../../utils/little-columns-settings";

const periodFormatter: { [key in PeriodTopMembers]: string } = {
  allTime: `All time`,
  week: `7 days`,
  month: `30 days`,
};

export const TopMembersBlock: React.FC = () => {
  const { data: allData, isLoading: loadingAllData } = useGetTopMembers({
    period: PeriodTopMembers.AllTime,
  });
  const { data: weekData, isLoading: loadingWeek } = useGetTopMembers({
    period: PeriodTopMembers.Week,
  });
  const { data: monthData, isLoading: loadingMonth } = useGetTopMembers({
    period: PeriodTopMembers.Month,
  });

  const { user } = useUser();

  if (loadingAllData || loadingWeek || loadingMonth) {
    return (
      <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
    );
  }

  if (!monthData?.data.length && !weekData?.data.length && !allData?.data.length) {
    return null;
  }

  return (
    <StatisticsTopBlockWithTabs tabs={["this week", "this month", "all time"]}>
      {[weekData, monthData, allData].map((periodData) => (
        <div key={periodData?.period}>
          <div className="flex items-end gap-10 mb-8">
            <h5 className="text-h5 text-white">
              {`Top ${user?.role !== Roles.User ? "affiliates" : "members"} for ${
                periodFormatter[periodData?.period || PeriodTopMembers.Week]
              }`.toLocaleUpperCase()}
            </h5>
          </div>
          <Table
            data={periodData?.data as unknown as TableDataType[]}
            columns={columnNames}
            className="table text-p1 w-full"
          />
        </div>
      ))}
    </StatisticsTopBlockWithTabs>
  );
};
