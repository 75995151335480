import { useAddAddress } from "@fe/app/actions/useAddAddress";
import { useGetInvitedAffiliates } from "@fe/app/actions/useGetInvitedAffiliates";
import { CopyText } from "@fe/app/ui-kit/copy";
import { ReactComponent as Add } from "@fe/assets/images/add.svg";
import { ReactComponent as Close } from "@fe/assets/images/cross.svg";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { GradientButton, Input, VitreusLoader } from "vit-ui-kit";

const addressLength = 42;

export interface AddAddressProps {
  handleClose: () => void;
}

type Inputs = {
  address: string;
};

export const AddAddress: React.FC<AddAddressProps> = ({ handleClose }) => {
  const { data, mutate, isLoading, isError } = useAddAddress();
  const { refetch } = useGetInvitedAffiliates({ limit: 0, offset: 0 });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    refetch();
  }, [data]);

  const onSubmit: SubmitHandler<Inputs> = (data) => mutate(data.address);

  const button = data ? (
    <GradientButton
      className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium"
      text={"close"}
      onClick={handleClose}
    />
  ) : (
    <GradientButton
      disabled={Object.keys(errors).length}
      className="bg-vitreus-gradient-main h-50 text-[12rem] leaning-[16rem] font-medium"
      text={"add"}
      type="submit"
    />
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="relative flex flex-col w-[470rem] bg-[#182A29] justify-center rounded-[20rem] sm:w-screen sm:m-auto"
    >
      <div className="absolute top-0 right-[-30rem] sm:right-[5rem]" onClick={handleClose}>
        <Close className="cursor-pointer" />
      </div>
      {isLoading ? (
        <div className="w-full h-[200rem] flex justify-center items-center">{VitreusLoader}</div>
      ) : isError ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[20rem] leaning-[28rem] font-medium text-white">Server Error</div>
          </div>

          {button}
        </div>
      ) : data ? (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="text-[20rem] leaning-[28rem] font-medium text-white">
              You successfully added:
            </div>
            <div className=" flex gap-8 text-[14rem] leaning-[18rem] text-vitreus-black-100 sm:!hidden">
              {getValues("address")} <CopyText text={getValues("address")} />
            </div>
            <div className="hidden gap-8 text-[14rem] leaning-[18rem] text-vitreus-black-100 sm:flex">
              {`${getValues("address").slice(0, 4)}...${getValues("address").slice(-6)}`}
              <CopyText text={getValues("address")} />
            </div>
          </div>

          {button}
        </div>
      ) : (
        <div className="flex flex-col gap-[32rem] justify-center px-24 py-[32rem]">
          <div className="flex flex-col gap-12 justify-start">
            <div className="flex gap-12">
              <div>
                <Add />
              </div>
              <div className="text-[20rem] leaning-[28rem] font-medium text-white">Add address</div>
            </div>
            <div className="text-[14rem] leaning-[18rem] text-vitreus-black-100">
              Enter the address you want to add in the field
            </div>
          </div>
          <div className="flex flex-col gap-10">
            <div className="text-[14rem] leaning-[18rem] text-vitreus-black-100">Address</div>
            <div className="mr-20 sm:mr-0">
              <Input
                aria-invalid={errors.address ? "true" : "false"}
                type="text"
                {...register("address", {
                  required: true,
                  minLength: addressLength,
                  maxLength: addressLength,
                })}
              />
              {errors.address && <span className="text-red">This field is wrong</span>}
            </div>
          </div>
          {button}
        </div>
      )}
    </form>
  );
};
