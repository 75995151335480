import { utils } from "ethers";

/**
 * Helper for convert big number in scientific notation to simple number in string
 * @param x possible big number with e+
 * @returns stringify number without e+
 *
 * @example
 * // before -  utils.formatEther(1.2e+21) - error
 * utils.formatEther(toFixed(burntFees));
 * // after - utils.formatEther(1200000000000000000000) - fine
 */
export const toFixed = (x: number | undefined): string => {
  if (!x) return "0";

  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      return "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      return x + new Array(e + 1).join("0");
    }
  }

  return "0";
};

export const round = (value: number, decimals: number) => {
  const result = Number(Math.floor(Number(value) * 10 ** decimals) / 10 ** decimals).toFixed(
    decimals,
  );

  return result;
};

export const USDFormatter = (value: string | number) =>
  new Intl.NumberFormat("en-US").format(
    +(Math.floor(Number(value) * 10 ** 6) / 10 ** 6).toFixed(2),
  );
