import DisclaimerModal from "@fe/app/components/disclaimer/disclaimer";
import { Roles } from "@fe/app/constants/roles";
import { RouterLinks } from "@fe/app/constants/router-links";
import { useUser } from "@fe/app/hooks/useUser";
import { ModalWindow } from "@fe/app/ui-kit/modal-window";
import { useConnectionStatus } from "@thirdweb-dev/react";
import { FC, useState } from "react";
import { Navigate } from "react-router-dom";

import background from "./bg.png";
import { ConnectWalletWindow } from "./components/connect-wallet";
import { LoginWindow } from "./components/login";

const rolesPages: Record<Roles, RouterLinks> = {
  admin: RouterLinks.Admin,
  user: RouterLinks.Statistics,
  manager: RouterLinks.Statistics,
};

export const Initializer: FC = () => {
  const { user } = useUser();
  const status = useConnectionStatus();

  const [isDisclaimer, setIsDisclaimer] = useState<boolean>(
    !localStorage.getItem("disclaimerAffiliate")?.length,
  );

  const handleCloseDisclaimer = () => {
    localStorage.setItem("disclaimerAffiliate", "true");
    setIsDisclaimer(false);
  };

  if (user) {
    return <Navigate to={rolesPages[user.role]} />;
  }

  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <img
        src={background}
        alt="background"
        className="absolute top-0 left-0 bottom-0 right-0 object-cover w-full h-full opacity-20 mix-blend-luminosity grayscale"
      />
      <div className={"w-fit sm:!w-full"}>
        <div className="relative flex flex-col w-[400rem] bg-[#182A29] justify-center items-center gap-12 rounded-[20rem] py-48 px-32 sm:w-full">
          {(status === "disconnected" || status === "unknown") && <ConnectWalletWindow />}
          {(status === "connected" || status === "connecting") && <LoginWindow />}
        </div>
      </div>
      {isDisclaimer && (
        <ModalWindow className="absolute py-[100rem] top-0 bottom-0 m-auto flex items-center">
          <DisclaimerModal handleClose={handleCloseDisclaimer} />
        </ModalWindow>
      )}
    </div>
  );
};
