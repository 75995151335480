import { config } from "../config/environment.config";
import { AppStorage } from "./Storage";

type FileTypes = "json" | "csv";

export class Api {
  static baseUri = config.affiliateApiUrl;

  static async get<D = Record<string, string | undefined>, T = FileTypes>(
    route: string,
    type: T = "json" as T,
    params?: {
      [key: string]: any;
    },
  ): Promise<D> {
    const tokens = AppStorage.get("tokens");

    const searchParams = new URLSearchParams({
      ...params,
    });

    const headers = new Headers();
    tokens && headers.set("Authorization", `Bearer ${tokens?.accessToken}`);

    const response = await fetch(
      config.affiliateApiUrl + route + `${params ? `?${searchParams}` : ""}`,
      {
        method: "GET",
        headers,
      },
    );

    if (type === "csv") {
      return (await response.blob()) as D;
    }

    return (await response.json()) as D;
  }

  static async post<D = Record<string, string | undefined>, T = Record<string, string | undefined>>(
    route: string,
    payload: T,
  ) {
    const tokens = AppStorage.get("tokens");

    const headers = new Headers();
    tokens && headers.set("Authorization", `Bearer ${tokens?.accessToken}`);
    headers.set("Content-Type", "application/json");

    const response = await fetch(config.affiliateApiUrl + route, {
      method: "POST",
      body: JSON.stringify(payload),
      headers,
    });

    return (await response.json()) as D;
  }

  static async patch<
    D = Record<string, string | undefined>,
    T = Record<string, string | undefined>,
  >(route: string, payload: T) {
    const tokens = AppStorage.get("tokens");

    const headers = new Headers();
    tokens && headers.set("Authorization", `Bearer ${tokens?.accessToken}`);
    headers.set("Content-Type", "application/json");

    const response = await fetch(config.affiliateApiUrl + route, {
      method: "PATCH",
      body: JSON.stringify(payload),
      headers,
    });

    return (await response.json()) as D;
  }
}
