import { Period } from "@fe/app/constants";
import { Api } from "@fe/app/helpers/Api";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export interface NumberOfMembersDto {
  numberOfMembers: number;
  numberOfMembersDiff: number;
  totalNumberOfMembers: number;
}

export interface NumberOfMembersData extends NumberOfMembersDto {
  period: Period;
}

export const useGetNumberOfMembers = ({
  period,
}: {
  period: Period;
}): UseQueryResult<NumberOfMembersData> =>
  useQuery([`useGetMembers${period}`], async () => {
    const response = await Api.get<NumberOfMembersDto>(
      `/analytics/number-of-members?period=${period}`,
    );

    return { ...response, period: period };
  });
